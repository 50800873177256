interface Slide {
  id: number;
  src: string;
}

export const AboutSlider: Slide[] = [
  {
    id: 1,
    src: '/img/0.jpg',
  },
  {
    id: 2,
    src: '/img/1.jpg',
  },
  {
    id: 3,
    src: '/img/2.jpg',
  },
];
