import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

interface LogoProps {
  src: string;
}

const Logo = ({ src }: LogoProps): ReactElement => {
  return (
    <Link to="/" className="logo">
      <img src={src} className="App-logo" alt="logo" />
    </Link>
  );
};

export { Logo };
