import React, { ReactElement } from 'react';
import { News } from '../news/News';
import './Main.scss';

const MainNewsPages = (): ReactElement => {
  return (
    <main className="main main--no-banner">
      <News />
    </main>
  );
};

export { MainNewsPages };
