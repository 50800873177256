import React, { ReactElement } from 'react';
import Marquee from 'react-fast-marquee';
import './RunningTape.scss';
import { useGlobalAppContext } from '../../shared/contexts/AppContext';

const RunningTape = (): ReactElement => {
  const { recruitmentText } = useGlobalAppContext();

  const array = [...Array(16).keys()].map((i) => i + 1);
  return (
    <Marquee className="running-tape" speed={130} gradient={false}>
      {array.map((value) => (
        <span className="running-tape__item" key={value}>
          {recruitmentText.runningTape}
        </span>
      ))}
    </Marquee>
  );
};

RunningTape.defaultProps = {
  title: '',
};

export { RunningTape };
