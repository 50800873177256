import React, { ReactElement, ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  children: ReactNode;
  href: string;
  className: string;
  handleClick?: (evt: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const LinkTo = ({ children, href, className, handleClick }: Props): ReactElement => {
  const externalLinkRegex = new RegExp('https?:\\/\\/(.*?)\\/', 'g');
  const isExternal = typeof href === 'string' && externalLinkRegex.exec(href) ? '_blank' : '';

  return (
    <>
      {!isExternal && (
        <Link to={href} className={className} onClick={handleClick}>
          {children}
        </Link>
      )}
      {isExternal && (
        <Link to={href} className={className} onClick={handleClick} target="_blank">
          {children}
        </Link>
      )}
    </>
  );
};

LinkTo.defaultProps = {
  handleClick: () => {},
};

export { LinkTo };
