import { PagesModel } from '../types/models/pagesModel';
import { Pages } from '../types/strapi/pages';
import { ListResponse } from '../types/strapi/response';
import { getBlocks } from '../utils/getBlocks';

export const mapPageFromDTO = (response: ListResponse<Pages>): PagesModel | undefined => {
  if (response.data.length > 0) {
    const firstElement = response.data[0];
    return {
      id: firstElement.id,
      slug: firstElement.attributes.Slug,
      blocks: getBlocks(firstElement.attributes.blocks),
      title: firstElement.attributes.title,
    };
  }
  return undefined;
};
