interface Form {
  class: string;
  title: string;
  subtitle: string;
  description: string;
}

const dataAll: { [index: string]: Form } = {
  directions: {
    class: 'form-callback--inside',
    title: 'Записаться на\u00A0курс',
    subtitle: '',
    description:
      '<span class="form-callback__descr-alert">24</span>человека прошедших курс работают у\u00A0нас',
  },
  main: {
    class: '',
    title: 'Остались вопросы?',
    subtitle: '<span>Оставьте заявку</span><span>и\u00A0мы\u00A0поможем.</span>',
    description: 'Напишите нам, чтобы записаться на\u00A0курс',
  },
};

function getFormData(location: string): Form {
  let data;
  switch (location) {
    case '/front-end':
    case '/web-master':
      dataAll.directions.description =
        '<span class="form-callback__descr-alert">5</span>человек, прошедших курс, работают у\u00A0нас';
      data = dataAll.directions;
      break;
    case '/content-manager':
      dataAll.directions.description =
        '<span class="form-callback__descr-alert">2</span>человека, прошедший курс, работает у\u00A0нас';
      data = dataAll.directions;
      break;
    case '/bitrix':
      dataAll.directions.description =
        '<span class="form-callback__descr-alert">2</span>человека, прошедший курс, работает у\u00A0нас';
      data = dataAll.directions;
      break;
    default:
      data = dataAll.main;
      break;
  }

  return data;
}

export { getFormData };
