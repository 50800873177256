import { ListResponse } from '../types/strapi/response';
import { Reviews } from '../types/strapi/reviews';
import { ReviewsModel } from '../types/models/reviewsModel';
import { prepareImage } from '../utils/prepareImage';

export const mapReviewsFromDTO = (response: ListResponse<Reviews>): ReviewsModel[] | undefined => {
  if (response.data.length > 0) {
    return response.data.map((d) => {
      return {
        id: d.id,
        title: d.attributes.title,
        name: d.attributes.name,
        previewText: d.attributes.previewText,
        detailText: d.attributes.detailText,
        image: d.attributes.image.data?.attributes
          ? prepareImage(d.attributes.image.data?.attributes)
          : undefined,
      };
    });
  }
  return undefined;
};
