import React, { createContext, ReactElement, useContext, useEffect, useState } from 'react';
import { IAppContext } from '../types/context';
import { RecruitmentModel } from '../types/models/recruitmentModel';
import { ReviewsModel } from '../types/models/reviewsModel';
import { API } from '../../api/api';
import { DirectionsModel } from '../types/models/directionsModel';
import { QuestionsModel } from '../types/models/questionsModel';

const AppContext = createContext<IAppContext>({
  reviews: [],
  recruitmentText: {
    directionDesc: '',
    runningTape: '',
  },
  directions: [],
  questions: [],
});

type Props = {
  children: JSX.Element | JSX.Element[];
};
export const AppContextProvider = ({ children }: Props): ReactElement => {
  const [reviews, setReviews] = useState<ReviewsModel[]>([]);
  const [recruitmentText, setRecruitmentText] = useState<RecruitmentModel>({
    directionDesc: '',
    runningTape: '',
  });
  const [directions, setDirections] = useState<DirectionsModel[]>([]);
  const [questions, setQuestions] = useState<QuestionsModel[]>([]);

  // Подгрузка данных с сервера
  useEffect(() => {
    API.getReviewsList().then((r) => {
      if (r) {
        setReviews(r);
      }
    });
    API.getRecruitmentTexts().then((r) => {
      if (r) {
        setRecruitmentText(r);
      }
    });
    API.getDirectionsList().then((r) => {
      if (r) {
        setDirections(r);
      }
    });
    API.getQuestionsList().then((r) => {
      if (r) {
        setQuestions(r);
      }
    });
  }, []);

  const value = {
    reviews,
    recruitmentText,
    directions,
    questions,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export function useGlobalAppContext(): IAppContext {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useContext(AppContext);
}
