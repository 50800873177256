import React, { ReactElement } from 'react';
import { MainDirectionsPages } from '../components/main/MainDirectionsPages';
import { DefaultLayout } from '../layouts/DefaultLayout';

const DirectionsPages = (): ReactElement => {
  return (
    <DefaultLayout>
      <MainDirectionsPages />
    </DefaultLayout>
  );
};

export { DirectionsPages };
