function clickNavLink(evt: React.MouseEvent<HTMLElement, MouseEvent>): undefined {
  evt.preventDefault();
  const element: EventTarget = evt.target;

  if (element instanceof HTMLAnchorElement) {
    const url = new URL(element.href);
    const hash = url.hash.slice(1);
    const ancorElement = document.getElementById(hash);

    if (!ancorElement) {
      window.location.href = element.href;
      return;
    }

    const ancorElementPosition = ancorElement.getBoundingClientRect();
    const ancorElementPositionTop = ancorElementPosition.top;

    const headerElement = document.querySelector('.header');

    if (!headerElement) {
      return;
    }

    const scrollPosition =
      ancorElementPositionTop + window.pageYOffset - headerElement.clientHeight / 2;

    document.body.scrollTop = scrollPosition;
    document.documentElement.scrollTop = scrollPosition;
  }
}

export { clickNavLink };
