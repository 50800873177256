import React, { ReactElement } from 'react';
import './AchievementsItems.scss';

interface Props {
  title: string;
  total: string;
  active: boolean;
}

const AchievementsItems = ({ title, total, active }: Props): ReactElement => {
  const IconClassName = `achievement__icon${active ? ' active' : ''}`;
  return (
    <div className="achievement">
      <svg
        className={IconClassName}
        width="71"
        height="68"
        viewBox="0 0 71 68"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M48.8991 38.7743L62.5287 57.8001L48.8991 68L35.0951 48.8071L21.7671 67.0896L8.4709 56.935L22.2545 38.773L0.420898 31.0751L5.66708 15.1872L27.1889 23.069V0H43.9787V23.069L65.4916 15.871L70.4209 31.7343L48.8991 38.7743Z" />
      </svg>
      <div className="achievement__name">{title}</div>
      <div className="achievement__total">{total}</div>
    </div>
  );
};

export { AchievementsItems };
