import React, { ReactElement } from 'react';
import { Error404 } from '../components/Error404/Error404';
import { DefaultLayout } from '../layouts/DefaultLayout';

const Page404 = (): ReactElement => {
  return (
    <DefaultLayout logo="/img/logo-dark-header.svg">
      <Error404 />
    </DefaultLayout>
  );
};

export { Page404 };
