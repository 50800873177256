import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  title: string;
  description: string;
  url: string;
}

const DirectionsItems = ({ title, description, url }: Props): ReactElement => {
  return (
    <li className="directions__item">
      <Link className="directions__item-link" to={`${url}`}>
        <div className="directions__item-descr">
          <div className="directions__item-title">{title}</div>
          <div className="directions__item-text">{description}</div>
        </div>

        <div className="btn btn--open directions__btn">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M24.3333 20.6667C24.8856 20.6667 25.3333 20.2189 25.3333 19.6667L25.3333 7.66669C25.3333 7.1144 24.8856 6.66669 24.3333 6.66669L12.3333 6.66669C11.781 6.66669 11.3333 7.1144 11.3333 7.66669C11.3333 8.21897 11.781 8.66669 12.3333 8.66669L21.9191 8.66669L8.29289 22.2929C7.90237 22.6834 7.90237 23.3166 8.29289 23.7071C8.68342 24.0976 9.31658 24.0976 9.70711 23.7071L23.3333 10.0809L23.3333 19.6667C23.3333 20.2189 23.7811 20.6667 24.3333 20.6667Z"
              fill="currentColor"
            />
          </svg>
        </div>
      </Link>
    </li>
  );
};

export { DirectionsItems };
