import { ListResponse } from '../types/strapi/response';
import { prepareImage } from '../utils/prepareImage';
import { Mentors } from '../types/strapi/mentors';
import { MentorsModel } from '../types/models/mentorsModel';

export const mapMentorsFromDTO = (response: ListResponse<Mentors>): MentorsModel[] | undefined => {
  if (response.data.length > 0) {
    return response.data.map((d) => {
      return {
        id: d.id,
        name: d.attributes.name,
        sort: d.attributes.sort,
        position: d.attributes.position,
        image: d.attributes.image.data?.attributes
          ? prepareImage(d.attributes.image.data?.attributes)
          : undefined,
      };
    });
  }
  return undefined;
};
