import React, { ReactElement } from 'react';
import { MainNewsPages } from '../components/main/MainNewsPages';
import { DefaultLayout } from '../layouts/DefaultLayout';

const NewsPages = (): ReactElement => {
  return (
    <DefaultLayout logo="/img/logo-dark-header.svg">
      <MainNewsPages />
    </DefaultLayout>
  );
};

export { NewsPages };
