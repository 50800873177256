import React, { ReactElement } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.scss';
import { MainPage } from './pages/MainPage';
import { DirectionsPages } from './pages/DirectionsPages';
import { NewsPages } from './pages/NewsPages';
import { ArticlePages } from './pages/ArticlePages';
import { Page404 } from './pages/Page404';
import { AppContextProvider } from './shared/contexts/AppContext';

const App = (): ReactElement => {
  return (
    <AppContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/directions">
            <Route path="/directions:direction" element={<DirectionsPages />} />
          </Route>
          <Route path="/news" element={<NewsPages />} />
          <Route path="/news">
            <Route path="/news:alias" element={<ArticlePages />} />
          </Route>
          <Route path="*" element={<Page404 />} />
        </Routes>
      </BrowserRouter>
    </AppContextProvider>
  );
};

export { App };
