interface Methods {
  id: number;
  title: string;
  previewDescription: string;
  description: string;
  link: {
    text: string;
    src: string;
  };
}

export const MethodsData: Methods[] = [
  {
    id: 1,
    title: 'Обучение проходит в онлайн формате',
    previewDescription: 'Все материалы и задания к ним подготовлены заранее опытными наставниками.',
    description:
      '<p>Учебные материалы школы упакованы в\u00A0курсы. Пройдя курс, студент знает базовую теорию по\u00A0предметной области и\u00A0умеет делать несложные задачи. Курс состоит из\u00A0<b>занятий</b> и\u00A0<b>курсовой работы</b>. </p> <p><b>Занятие</b>\u00A0— учебная единица в\u00A0составе курса. Занятие состоит из\u00A0теории, задач, вопросов для самопроверки. За\u00A0одно занятие студент получает конкретные компетенции. Например, занятие по\u00A0работе с\u00A0figma или занятие по\u00A0БЭМ. Каждое занятие имеет дедлайн. В\u00A0дату дедлайна наставник проверяет задачи, закрывает занятие для студента или возвращает на\u00A0доработку, а\u00A0также ставит оценку. </p> <p><b>Курсовая работа</b>\u00A0— небольшой проект, покрывающий все компетенции курса. Например это вёрстка лендинга по\u00A0курсу вёрстки, или разработка логики по\u00A0готовой верстке на\u00A0курсе по\u00A0PHP. Экзамен\u00A0— устное собеседование студента с\u00A0наставником. Экзамен проводится в\u00A0конце курса.</p> <p>После прохождения обучения студент получает сертификат школы.</p> <p style="text-align:center"><img width="400px" src="/img/sergeev.jpg"></p>',
    link: {
      text: 'Подробнее',
      src: '',
    },
  },
  {
    id: 2,
    title: 'Прямое общение с\u00A0наставниками в\u00A0Slack',
    previewDescription:
      'Каждый ученик может задать любой интересующий его вопрос по\u00A0процессу обучения или о\u00A0выполнении задания',
    description:
      '<p>Общение студентов с\u00A0наставниками происходит в\u00A0системе <b>slack</b>.</p> <p>Студент может задавать вопросы как в\u00A0чате группы и\u00A0получать ответы одногруппников, так и\u00A0писать в\u00A0личку наставнику. </p> <p> Мы\u00A0поощряем помощь студентов друг другу, т.к когда объясняешь материал другому\u00A0— лучше усваиваешь материал сам.</p> <p><img width="100%" src="/img/slack.png"></p>',
    link: {
      src: '',
      text: 'Подробнее о\u00A0slack',
    },
  },
  {
    id: 3,
    title: 'Сдача домашних работ через сервис git',
    previewDescription:
      'Каждую выполненную задачу ученик загружает в\u00A0свой аккаунт. Наставник\u00A0проверит каждого ученика и\u00A0даст индивидуальные коментарии',
    description:
      '<p>Для сдачи заданий, связанных с\u00A0написанием кода мы\u00A0используем несложный gitflow.</p> <p>Студентов мы\u00A0учим базовым операциям git (push, pull, создание веток) по\u00A0готовой инструкции.</p> <p><img width="100%" src="/img/git.jpg"></p>',
    link: {
      src: '',
      text: 'Подробнее о\u00A0git',
    },
  },
];
