import React, { ReactElement } from 'react';
import { Mentor } from './Mentor';
import { MentorsModel } from '../../shared/types/models/mentorsModel';

interface PageProps {
  data: MentorsModel[];
}
const MentorsDesctop = ({ data }: PageProps): ReactElement => {
  return (
    <>
      {data.map((item) => {
        return (
          <Mentor key={item.id} name={item.name} position={item.position} image={item.image} />
        );
      })}
    </>
  );
};

export { MentorsDesctop };
