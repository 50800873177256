import React, { useMemo } from 'react';

interface WysiwygProps {
  className?: string;
  children: string;
}

export const Wysiwyg = ({ className, children }: WysiwygProps): React.ReactElement => {
  const text = useMemo(() => children, [children]);

  return <div className={className} dangerouslySetInnerHTML={{ __html: text }} />;
};

Wysiwyg.defaultProps = {
  className: '',
};
