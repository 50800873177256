export const getParams = (blocks: string[], pageSize?: number, pageNumber?: number): string => {
  const array = blocks.map((b, index) => `populate[${index}]=${b}`);
  if (pageSize) {
    array.push(`pagination[pageSize]=${pageSize}`);
  }

  if (pageNumber) {
    array.push(`pagination[page]=${pageNumber}`);
  }
  return array.join('&');
};
