import React, { ReactElement, useState } from 'react';
import './Question.scss';
import { CircleSwitch } from '../circleSwitch/CircleSwitch';
import { Wysiwyg } from '../wysiwyg/Wysiwyg';

interface Props {
  question: string;
  answer: string;
  isFirst: boolean;
}

const Question = ({ question, answer, isFirst }: Props): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);

  const switchContainerClass = isFirst
    ? `question ${!isOpen ? 'active' : ''}`
    : `question ${isOpen ? 'active' : ''}`;

  function toggleQuestion() {
    setIsOpen(!isOpen);
  }

  return (
    <article>
      <div
        className={switchContainerClass}
        role="button"
        tabIndex={0}
        onClick={toggleQuestion}
        onKeyPress={() => {}}
      >
        <div className="question__header">
          <span className="question__heading">{question}</span>
          <div className="question__switch">
            <CircleSwitch isFirst={isFirst} isOpen={isOpen} />
          </div>
        </div>
        <div className="question__descr">
          <Wysiwyg className="question__text">{answer}</Wysiwyg>
        </div>
      </div>
    </article>
  );
};

export { Question };
