import { BlocksType, BlocksVariants } from '../types/models/blocksModel';
import { StrapiBasicComponent } from '../types/strapi/response';

export const getBlocks = (blocks: StrapiBasicComponent[]): BlocksType | undefined => {
  if (blocks) {
    return blocks.reduce((acc: BlocksType, b: StrapiBasicComponent) => {
      // eslint-disable-next-line no-underscore-dangle
      const componentName = b.__component.replace(/.*\./, '') as BlocksVariants;

      if (!acc[componentName]) {
        acc[componentName] = [];
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      acc[componentName] = acc[componentName].concat(b);

      return acc;
    }, {});
  }

  return undefined;
};
