import React, { ReactElement } from 'react';
import './Programs.scss';
import { Question } from '../questions/Question';
import { ProgramsModel } from '../../shared/types/models/programsModel';

interface PageProps {
  data?: ProgramsModel[];
}
const Programs = ({ data }: PageProps): ReactElement | null => {
  if (!data || data.length === 0) {
    return null;
  }

  const program = data[0];

  return (
    <section className="program" id="program">
      <div className="container">
        <div className="program__wrapper">
          <div className="program__title">
            <h2 className="h2">{program.title}</h2>
            <div className="program__text">{program.description}</div>
          </div>
          <div className="program__list">
            {program.list.map((item, index) => {
              return (
                <Question
                  key={item.id}
                  question={item.title}
                  answer={item.description}
                  isFirst={index === 0}
                />
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

Programs.defaultProps = {
  data: [],
};

export { Programs };
