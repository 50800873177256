import React, { ReactElement } from 'react';
import './Copyright.scss';

const Copyright = (): ReactElement => {
  const year = new Date();
  return (
    <div className="copyright">
      <div>© school.kokoc.tech, 2016-{year.getFullYear()}</div>
      <a
        href="/policy/policy_kokoc_school.pdf"
        target="_blank"
        rel="noopener noreferrer"
        className="political-site"
      >
        Политика обработки персональных данных
      </a>
    </div>
  );
};

export { Copyright };
