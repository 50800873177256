import React, { ReactElement } from 'react';
import Slider from 'react-slick';
import { Mentor } from './Mentor';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { MentorsModel } from '../../shared/types/models/mentorsModel';

interface PageProps {
  data: MentorsModel[];
}
const MentorsMobile = ({ data }: PageProps): ReactElement => {
  const settings = {
    slidesToShow: 4,
    slidesToScroll: 4,
    infinite: data.length > 4,
    dots: false,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 2,
          infinite: data.length > 2,
          centerMode: true,
          centerPadding: '50px',
        },
      },
      {
        breakpoint: 480,
        settings: {
          centerMode: true,
          centerPadding: '30px',
          slidesToShow: 1.1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {data.map((item) => {
        return (
          <Mentor key={item.id} name={item.name} position={item.position} image={item.image} />
        );
      })}
    </Slider>
  );
};

export { MentorsMobile };
