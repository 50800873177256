import React, { ReactElement, useMemo, useState } from 'react';
import './Review.scss';
import { LinkWithArrow } from '../linkWithArrow/LinkWithArrow';
import { ModalWindow } from '../modalPopup/ModalWindow';
import { CircleSwitch } from '../circleSwitch/CircleSwitch';
import { Wysiwyg } from '../wysiwyg/Wysiwyg';
import { getScrollWidth } from '../../functions/helpers/scrollWidth';
import { Image } from '../../shared/types/strapi/image';

interface Props {
  name: string;
  title: string;
  previewText: string;
  detailText?: string;
  image?: Image;
  // video: string;
}

const Review = ({ name, title, detailText, previewText, image }: Props): ReactElement => {
  const [showModal, setShowModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useMemo(() => {
    const html = document.body;
    const header = document.querySelector('header');
    if (showModal) {
      const scrollWidth = getScrollWidth();
      html.style.marginRight = `${scrollWidth}px`;

      if (header) {
        header.style.maxWidth = `calc(100% - ${scrollWidth}px)`;
      }
    } else {
      html.style.marginRight = '0';
      if (header) {
        header.style.maxWidth = '100%';
      }
    }
  }, [showModal]);

  return (
    <article className="review">
      <div className="review__img-wrapper">
        {image && <img src={image.url} alt={name} className="review__img" />}
      </div>

      <div className="review__heading">
        <span className="review__name">{name}</span>
        <span className="review__title">{title}</span>
      </div>
      <Wysiwyg className="review__descr">{previewText}</Wysiwyg>
      {/* {video !== '' && ( */}
      {/*  <LinkWithArrow */}
      {/*    text="Видео отзыв" */}
      {/*    src={video} */}
      {/*    ClassName="review__video-link" */}
      {/*    isOpen={isOpen} */}
      {/*    setIsOpen={setIsOpen} */}
      {/*  /> */}
      {/* )} */}
      {detailText !== '' && (
        <div className="review__show-modal">
          <CircleSwitch isOpen={showModal} text="Показать полностью" setIsOpen={setShowModal} />
        </div>
      )}
      {showModal && (
        <ModalWindow isOpen={showModal} setIsOpen={setShowModal}>
          <article className="review">
            <div className="review__img-wrapper">
              {image && <img src={image.url} alt={name} className="review__img" />}
            </div>
            <div className="review__heading">
              <span className="review__name">{name}</span>
              <span className="review__title">{title}</span>
            </div>
            {detailText && <Wysiwyg className="review__descr">{detailText}</Wysiwyg>}
            {/* {video !== '' && ( */}
            {/*  <LinkWithArrow */}
            {/*    text="Видео отзыв" */}
            {/*    src={video} */}
            {/*    ClassName="review__video-link" */}
            {/*    isOpen={isOpen} */}
            {/*    setIsOpen={setIsOpen} */}
            {/*  /> */}
            {/* )} */}
          </article>
        </ModalWindow>
      )}
    </article>
  );
};

Review.defaultProps = {
  detailText: '',
  image: undefined,
};

export { Review };
