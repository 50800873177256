import { useState, useEffect, useCallback } from 'react';

import breakpoints from '../config/breakpoints.json';

export const useWindowWidth = (): {
  width: number;
  isPhone: boolean;
  isSmartPhone: boolean;
  isTable: boolean;
  isLaptop: boolean;
} => {
  const [width, setWidth] = useState(0);

  const onResize = useCallback(() => {
    setWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);

  return {
    width,
    isPhone: width < breakpoints.phone,
    isSmartPhone: width < breakpoints.smartphone,
    isTable: width < breakpoints.tablet,
    isLaptop: width <= breakpoints.laptop,
  };
};
