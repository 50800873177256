import { Params } from 'react-router-dom';

interface Menu {
  id: number;
  name: string;
  src: string;
}

const NavMenuDataAll: { [index: string]: Menu[] } = {
  directions: [
    {
      id: 1,
      name: 'Программа обучения',
      src: '#program',
    },
    {
      id: 2,
      name: 'Наставники курса',
      src: '#mentors',
    },
    {
      id: 3,
      name: 'Отзывы',
      src: '#reviews',
    },
    {
      id: 4,
      name: 'Вопросы',
      src: '#questions',
    },
  ],
};

const DefaultItems: Menu[] = [
  {
    id: 5,
    name: 'Направления',
    src: '/#directions',
  },
  {
    id: 6,
    name: 'Как обучаем',
    src: '/#methods',
  },
  {
    id: 7,
    name: 'О нас',
    src: '/#about',
  },
  {
    id: 8,
    name: 'Отзывы',
    src: '/#reviews',
  },
  {
    id: 9,
    name: 'Вопросы',
    src: '/#questions',
  },
];

function getNavMenuData(params: Params<string>): Menu[] {
  let data = DefaultItems;

  switch (true) {
    case Boolean(params.direction):
      data = NavMenuDataAll.directions;
      break;

    default:
      break;
  }

  return data;
}

export { getNavMenuData, DefaultItems };
