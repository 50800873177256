import React, { ReactElement } from 'react';
import './Questions.scss';
import { Question } from './Question';
import { useGlobalAppContext } from '../../shared/contexts/AppContext';

export const Questions = (): ReactElement | null => {
  const { questions } = useGlobalAppContext();

  if (questions.length === 0) {
    return null;
  }

  return (
    <section className="questions" id="questions">
      <div className="container">
        <div className="questions__wrapper">
          <h2 className="h2 questions__title">Часто задаваемые вопросы</h2>
          <div className="questions__list">
            {questions.map((item, index) => {
              return <Question {...item} isFirst={index === 0} key={item.id} />;
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

Questions.defaultProps = {};
