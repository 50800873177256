import { ListResponse } from '../types/strapi/response';
import { News } from '../types/strapi/news';
import { prepareImage } from '../utils/prepareImage';
import { NewsModel } from '../types/models/newsModel';

export const mapNewsFromDTO = (response: ListResponse<News>): NewsModel[] | [] => {
  if (response.data.length > 0) {
    return response.data.map((d) => {
      const date = d.attributes.date && new Date(d.attributes.date);
      const dateFormatted = date
        ? `${date.toLocaleString('ru', {
            month: 'long',
            day: 'numeric',
          })}, ${date.getFullYear()}`
        : '';

      return {
        id: d.id,
        date: dateFormatted,
        url: `/news/${d.attributes.slug}`,
        slug: d.attributes.slug,
        title: d.attributes.title,
        titleShort:
          d.attributes.title.length > 63
            ? `${d.attributes.title.slice(0, 60)}...`
            : d.attributes.title,
        sort: d.attributes.sort,
        detailText: d.attributes.detailText,
        previewImage: d.attributes.previewImage.data?.attributes
          ? prepareImage(d.attributes.previewImage.data?.attributes)
          : undefined,
        detailImage: d.attributes.detailImage.data?.attributes
          ? prepareImage(d.attributes.detailImage.data?.attributes)
          : undefined,
        useful_links: d.attributes.useful_links,
      };
    });
  }
  return [];
};
