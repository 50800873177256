import React, { ReactElement } from 'react';
import './Mentors.scss';
import { MentorsDesctop } from './MentorsDesctop';
import { MentorsMobile } from './MentorsMobile';
import { MentorsModel } from '../../shared/types/models/mentorsModel';
import { useWindowWidth } from '../../shared/hooks/useWindowWidth';

interface PageProps {
  data?: MentorsModel[];
}

const Mentors = ({ data }: PageProps): ReactElement | null => {
  const { isTable } = useWindowWidth();
  if (!data || data.length === 0) {
    return null;
  }

  return (
    <>
      <section id="mentors" className={`mentors ${isTable ? ' mentors--slider' : ''}`}>
        <div className="container">
          <div className="mentors__title-wrapper">
            <h2 className="h2 mentors__title">Наставники курса</h2>
            <div className="mentors__descr">
              Каждый из наставников прошел большой путь в мире разработки и имеет не только большой
              багаж знаний но и личный опыт, которым поделиться с вами.
            </div>
          </div>
          <div className="mentors__wrapper">
            {!isTable && <MentorsDesctop data={data} />}
            {isTable && <MentorsMobile data={data} />}
          </div>
        </div>
      </section>
    </>
  );
};

Mentors.defaultProps = {
  data: [],
};

export { Mentors };
