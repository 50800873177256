import React, { ReactElement, useState } from 'react';
import InputMask from 'react-input-mask';
import { useLocation } from 'react-router-dom';
import './Form.scss';
import './Field.scss';
import { getFormData } from '../../dataFiles/FormData';
import { ValidateForm } from '../../functions/form/validation';
import { Wysiwyg } from '../wysiwyg/Wysiwyg';

const Form = (): ReactElement => {
  const location = useLocation();
  const form = getFormData(location.pathname);
  const [status, setStatus] = useState('');
  const [AddClassInputTel, setAddClassInputTel] = useState('');
  const [AddClassInputEmail, setAddClassInputEmail] = useState('');
  const [AddClassInputName, setAddClassInputName] = useState('');

  let pageFrom = '';
  switch (location.pathname) {
    case '/front-end':
      pageFrom = 'Frontend\u2011разработчик';
      break;
    case '/content-manager':
      pageFrom = 'Контент\u2011менеджер';
      break;
    case '/web-master':
      pageFrom = 'Веб\u2011мастер';
      break;
    case '/bitrix':
      pageFrom = 'Bitrix\u2011разработчик';
      break;
    default:
      pageFrom = 'Главная';
      break;
  }

  return (
    <section className={`form-callback ${form.class}`} id="form-callback">
      <div className="container">
        <div className="form-callback__wrapper">
          <div className="form-callback__header">
            <span className="h2 form-callback__title">
              <span>{form.title}</span>
            </span>
            {form.subtitle && (
              <Wysiwyg className="h2 form-callback__subtitle">{form.subtitle}</Wysiwyg>
            )}
            {form.description && (
              <Wysiwyg className="form-callback__descr">{form.description}</Wysiwyg>
            )}
          </div>
          <form
            className={`form-callback__form ${status}`}
            method="POST"
            onSubmit={(e) => {
              ValidateForm(
                e,
                setAddClassInputName,
                setAddClassInputEmail,
                setAddClassInputTel,
                setStatus
              );
            }}
            autoComplete="off"
          >
            <div className="form-field">
              <input
                type="text"
                onChange={() => {
                  setAddClassInputName('');
                }}
                onFocus={() => {
                  setAddClassInputName('');
                }}
                name="name"
                autoComplete="off"
                placeholder="Имя"
                className={`form-field__field ${AddClassInputName ? 'error' : ''}`}
              />
              <span className="form-field__message">{AddClassInputName}</span>
            </div>
            <div className="form-field">
              <InputMask
                mask="+7(999) 999-99-99"
                className={`form-field__field ${AddClassInputTel ? 'error' : ''}`}
                type="tel"
                placeholder="+7 (000) 000-00-00"
                name="phone"
                autoComplete="off"
                onChange={() => {
                  setAddClassInputTel('');
                }}
              />
              <span className="form-field__message">{AddClassInputTel}</span>
            </div>
            <div className="form-field">
              <input
                type="text"
                onChange={() => {
                  setAddClassInputEmail('');
                }}
                onFocus={() => {
                  setAddClassInputEmail('');
                }}
                name="email"
                autoComplete="off"
                placeholder="name@mail"
                className={`form-field__field ${AddClassInputEmail ? 'error' : ''}`}
              />
              <span className="form-field__message">{AddClassInputEmail}</span>
            </div>

            <div className="success-message">Ваша заявка принята!</div>
            <div className="error-message">
              Произошел сбой при отправке вашей заявки, попробуйте чуть позже еще раз!
            </div>
            <a
              href="/policy/policy_kokoc_school.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="political-site"
            >
              Политика обработки персональных данных
            </a>

            <input type="hidden" name="page" autoComplete="off" value={pageFrom} />
            <button className="btn form-callback__btn" type="submit">
              Отправить заявку
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export { Form };
