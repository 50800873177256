import React, { ReactElement } from 'react';
import { LinkTo } from '../linkTo/LinkTo';

interface Props {
  name: string;
  src: string;
  handleClick: (evt: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const NavMenuItem = ({ name, src, handleClick }: Props): ReactElement => {
  return (
    <li className="nav-menu__item">
      <LinkTo href={src} className="nav-menu__link" handleClick={handleClick}>
        {name}
      </LinkTo>
    </li>
  );
};

export { NavMenuItem };
