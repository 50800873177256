interface Achievement {
  id: number;
  title: string;
  total: string;
  active: boolean;
}

export const AchievementsData: Achievement[] = [
  {
    id: 1,
    title: 'В рейтинге CRM\u2011интеграторов Москвы',
    total: '13',
    active: true,
  },
  {
    id: 2,
    title: 'В рейтинге разработчиков сайтов Екатеринбурга',
    total: '20',
    active: true,
  },
  {
    id: 3,
    title: 'В рейтинге digital\u2011подрядчиков госструктур',
    total: '17',
    active: true,
  },
];
