import { AxiosResponse } from 'axios';
import { ElementResponse, ListResponse } from '../shared/types/strapi/response';
import { Pages } from '../shared/types/strapi/pages';
import { instanceApi } from './instanceApi';
import { mapPageFromDTO } from '../shared/mapper/mapPageFromDTO';
import { mapRunningTapeFromDTO } from '../shared/mapper/mapRunningTapeFromDTO';
import { mapDirectionsFromDTO } from '../shared/mapper/mapDirectionsFromDTO';
import { Recruitment } from '../shared/types/strapi/recruitment';
import { mapReviewsFromDTO } from '../shared/mapper/mapReviewsFromDTO';
import { mapQuestionsFromDTO } from '../shared/mapper/mapQuestionsFromDTO';
import { PagesModel } from '../shared/types/models/pagesModel';
import { mapNewsFromDTO } from '../shared/mapper/mapNewsFromDTO';
import { getParams } from '../functions/helpers/getParams';
import { DirectionsDetailModel, DirectionsModel } from '../shared/types/models/directionsModel';
import { ReviewsModel } from '../shared/types/models/reviewsModel';
import { NewsModel, NewsModelWithPagination } from '../shared/types/models/newsModel';
import { QuestionsModel } from '../shared/types/models/questionsModel';
import { mapDirectionFromDTO } from '../shared/mapper/mapDirectionFromDTO';
import { clickNavLink } from '../functions/helpers/clickNavLink';

export const API = {
  getPage(page: string): Promise<PagesModel | undefined> {
    const params = getParams(['blocks']);
    return instanceApi
      .get(`pages?filters[slug]=${page}&${params}`)
      .then((r: AxiosResponse<ListResponse<Pages>>) => {
        return mapPageFromDTO(r.data);
      });
  },
  getRecruitmentTexts(): Promise<Recruitment | undefined> {
    return instanceApi
      .get(`recruitment-date`)
      .then((r: AxiosResponse<ElementResponse<Recruitment>>) => {
        return mapRunningTapeFromDTO(r.data);
      });
  },
  getDirectionsList(): Promise<DirectionsModel[] | undefined> {
    return instanceApi.get(`directions`).then((r) => {
      return mapDirectionsFromDTO(r.data);
    });
  },
  getReviewsList(): Promise<ReviewsModel[] | undefined> {
    const params = getParams(['image'], 4);
    return instanceApi.get(`reviews?${params}`).then((r) => {
      return mapReviewsFromDTO(r.data);
    });
  },
  getNewsListForSlider(pageSize: number): Promise<NewsModel[]> {
    const params = getParams(['previewImage', 'detailImage'], pageSize);
    return instanceApi.get(`news?${params}`).then((r) => {
      return mapNewsFromDTO(r.data);
    });
  },
  getNewsList(pageSize: number, pageNumber?: number): Promise<NewsModelWithPagination> {
    const params = getParams(['previewImage', 'detailImage'], pageSize, pageNumber);
    return instanceApi.get(`news?${params}`).then((r) => {
      return {
        data: mapNewsFromDTO(r.data),
        pagination: r.data.meta.pagination,
      };
    });
  },
  getQuestionsList(): Promise<QuestionsModel[] | undefined> {
    return instanceApi.get(`questions`).then((r) => {
      return mapQuestionsFromDTO(r.data);
    });
  },
  getDirectionBySlug(slug: string): Promise<DirectionsDetailModel | undefined> {
    const params = getParams(['blocks', 'blocks.list', 'mentors', 'mentors.image']);
    return instanceApi.get(`directions?filters[slug]=${slug}&${params}`).then((r) => {
      return mapDirectionFromDTO(r.data);
    });
  },
  getNewsBySlug(slug: string) {
    const params = getParams(['previewImage', 'detailImage', 'useful_links']);
    return instanceApi.get(`news?filters[slug]=${slug}&${params}`).then((r) => {
      const items = mapNewsFromDTO(r.data);
      return items.length > 0 ? items[0] : undefined;
    });
  },
};
