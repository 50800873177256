import React, { ReactElement, useState } from 'react';
import { LinkWithArrow } from '../linkWithArrow/LinkWithArrow';
import { ModalWindow } from '../modalPopup/ModalWindow';
import { Wysiwyg } from '../wysiwyg/Wysiwyg';

interface Props {
  title: string;
  description: string;
  previewDescription: string;
  text: string;
  src: string;
}

const MethodsItem = ({
  title,
  description,
  previewDescription,
  text,
  src,
}: Props): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <li className="methods__item">
      <div className="methods__item-title">{title}</div>
      <div className="methods__item-text">{previewDescription}</div>
      <LinkWithArrow
        text={text}
        src={src}
        ClassName="methods__item-link"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      {isOpen && (
        <ModalWindow isOpen={isOpen} setIsOpen={setIsOpen}>
          <Wysiwyg className="methods__item-text">{description}</Wysiwyg>
        </ModalWindow>
      )}
    </li>
  );
};

export { MethodsItem };
