import React, { ReactElement } from 'react';
import './CircleSwitch.scss';

interface State {
  text?: string;
  setIsOpen?: (value: boolean) => void;
  isOpen: boolean;
  isFirst?: boolean;
}

const CircleSwitch = ({ text, isOpen, setIsOpen, isFirst }: State): ReactElement => {
  const switchContainerClass = isFirst
    ? `circle-switch ${!isOpen ? 'active' : ''}`
    : `circle-switch ${isOpen ? 'active' : ''}`;

  function toggleSwitch() {
    if (typeof setIsOpen === 'function') {
      setIsOpen(!isOpen);
    }
  }

  return (
    <button className={switchContainerClass} onClick={toggleSwitch} type="button">
      <span className="circle-switch__icon" />
      {text !== '' && <span className="circle-switch__text">{text}</span>}
    </button>
  );
};

CircleSwitch.defaultProps = {
  text: '',
  isFirst: false,
  setIsOpen: null,
};

export { CircleSwitch };
