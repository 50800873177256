import emailjs from '@emailjs/browser';

type SetState = (value: string) => void;

function ValidateForm(
  e: React.SyntheticEvent,
  setAddClassInputName: SetState,
  setAddClassInputEmail: SetState,
  setAddClassInputTel: SetState,
  setStatus: SetState
): void {
  let check = true;

  const requiredInput = (value: string, func: SetState) => {
    if (!value) {
      func('Поле обязательно к заполнению');
      check = false;
    }

    return Boolean(value);
  };

  const validateEmail = (email: string, func: SetState) => {
    const isEmail = requiredInput(email, func);

    if (isEmail) {
      const re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

      if (!re.test(email.toLowerCase())) {
        func('Введите коректный email');
        check = false;
      }
    }
  };

  const validatePhohe = (phone: string, func: SetState) => {
    const isPhone = requiredInput(phone, func);

    if (isPhone) {
      const re = /\+7\(\d{3}\)\s\d{3}-\d{2}-\d{2}/;

      if (!re.test(phone)) {
        func('Введите коректный телефон');
        check = false;
      }
    }
  };

  const target = e.target as typeof e.target & {
    name: { value: string };
    phone: { value: string };
    email: { value: string };
  };

  e.preventDefault();
  requiredInput(target.name.value, setAddClassInputName);
  validateEmail(target.email.value, setAddClassInputEmail);
  validatePhohe(target.phone.value, setAddClassInputTel);

  if (check) {
    emailjs.sendForm('service_mua9gyc', 'template_fiuv49f', 'form', 'qig6dmCTCYfnpNxXx').then(
      () => setStatus('success'),
      () => setStatus('no-sending')
    );
  }
}

export { ValidateForm };
