interface About {
  id: number;
  title: string;
  description: string;
  src: string;
  url: string;
}

export const AboutData: About[] = [
  {
    id: 16,
    title: 'Разработка сайта для DHL Express',
    description:
      'Компания DHL\u00A0— международный лидер в\u00A0области экспресс\u2011доставки и\u00A0логистики.',
    src: '/img/dhl.png',
    url: 'https://kokoc.tech/portfolio/razrabotka-sayta-dhl-express',
  },
  {
    id: 17,
    title: 'Разработка интернет\u2011магазина МАРР РУССИЯ для физических и\u00A0юридических лиц',
    description:
      'МАРР РУССИЯ\u00A0— крупнейший дистрибьютор продуктов питания и\u00A0производитель мясных полуфабрикатов для рынка HoReCa.',
    src: '/img/marr.png',
    url: 'https://kokoc.tech/portfolio/internet-magazin-marr-russia-dlya-fiz-i-yur-lits',
  },
  {
    id: 18,
    title: 'Корпоративный сайт для r_keeper',
    description:
      'R_keeper\u00A0— лидер рынка автоматизации предприятий в\u00A0сфере гостеприимства и\u00A0развлечений в\u00A0России и\u00A0СНГ.',
    src: '/img/r_keeper.png',
    url: 'https://kokoc.tech/portfolio/korporativnyy-sayt-dlya-r-keeper',
  },

  {
    id: 19,
    title: 'Платформа для киберфестиваля The StandOff',
    description:
      'Positive Technologies\u00A0— ведущий мировой поставщик корпоративных решений для кибербезопасности, управления уязвимостями систем и\u00A0анализа их\u00A0инцидентов и\u00A0угроз, а\u00A0также защиты приложений.',
    src: '/img/the_standoff.png',
    url: 'https://kokoc.tech/portfolio/platforma-dlya-kiberfestivalya-the-standoff',
  },

  {
    id: 20,
    title: 'Создание сайта для клиники оперативной урологии им И.М. Сеченова',
    description:
      'Клиника урологии Сеченовского университета открыта в\u00A01866 году и\u00A0постоянно развивается на\u00A0протяжении всей своей истории.',
    src: '/img/clinic.png',
    url:
      'https://kokoc.tech/portfolio/sozdanie-sayta-dlya-kliniki-operativnoy-urologii-im-im-sechenova',
  },

  {
    id: 21,
    title: 'Интернет\u00A0— магазин для Фарм\u2011Инвест',
    description:
      'Фарм\u00A0— Инвест работает как поставщик медицинской техники, лабораторного оборудования, медицинского автотранспорта и\u00A0расходных материалов на\u00A0территории России и\u00A0СНГ с\u00A01993\u00A0г.',
    src: '/img/farm.png',
    url: 'https://kokoc.tech/portfolio/internet-magazin-dlya-farm-invest',
  },
  {
    id: 11,
    title: 'Дизайн интерфейса для ADVgame',
    description: 'ADVGame\u00A0— это CPA\u2011площадка для продвижения онлайн\u2011игр.',
    src: '/img/adv-game.jpg',
    url: 'https://kokoc.tech/portfolio/dizayn-interfeysov-dlya-advgame',
  },

  {
    id: 12,
    title: 'Разработка интерфейса для сервиса Vitamin',
    description:
      'Витамин\u00A0— это стартап, который позволяет контролировать финансы рекламных кабинетов в\u00A0режиме одного окна.',
    src: '/img/vitamin.jpg',
    url: 'https://kokoc.tech/portfolio/razrabotka-interfeysa-dlya-servisa-vitamin',
  },

  {
    id: 15,
    title: 'Powerlay\u00A0— проектирование и\u00A0дизайн интерфейса',
    description:
      'Powerlay\u00A0— сервис, который позволяет сэкономить время при создании смет для диджитал агентств и\u00A0фрилансеров.',
    src: '/img/powerlay.jpg',
    url: 'https://kokoc.tech/portfolio/powerlay-proektirovanie-i-dizayn-interfeysov',
  },
];
