import React, { ReactElement } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';
import { Cookie } from '../components/cookie/Cookie';
import { Footer } from '../components/footer/Footer';
import { Header } from '../components/header/Header';

interface Props {
  children: JSX.Element | JSX.Element[];
  logo?: string;
}

const DefaultLayout = ({ children, logo = '/img/logo.svg' }: Props): ReactElement => {
  const location = useLocation();
  const [cookie] = useCookies(['cookie-policy']);

  return (
    <>
      <Header logo={logo} />
      {children}
      <Footer modification={`${location.pathname !== '/' ? 'footer--inside' : ''}`} />
      {!cookie['cookie-policy'] && <Cookie />}
    </>
  );
};

DefaultLayout.defaultProps = {
  logo: '/img/logo.svg',
};

export { DefaultLayout };
