import React, { ReactElement, useEffect, useState } from 'react';
import { Banner } from '../main-banner/Banner';
import { RunningTape } from '../running-tape/RunningTape';
import { Directions } from '../directions/Directions';
import { Methods } from '../methods/Methods';
import { About } from '../about/About';
import { Reviews } from '../reviews/Reviews';
import { Questions } from '../questions/Questions';
import { Form } from '../form/Form';
import { NewsSlider } from '../news/NewsSlider';
import { PagesModel } from '../../shared/types/models/pagesModel';
import { API } from '../../api/api';

const Main = (): ReactElement => {
  const [pageData, setPageData] = useState<PagesModel>();

  useEffect(() => {
    API.getPage('main').then((r) => {
      if (r) {
        setPageData(r);
      }
    });
    // не устанавливать сюда никаких зависимостей
  }, []);

  return (
    <main className="main">
      <Banner data={pageData?.blocks?.banner} />
      <RunningTape />
      <Directions />
      <Methods />
      <About />
      <Reviews />
      <Questions />
      <Form />
      <NewsSlider titleBlock="Новости" modification="main" />
    </main>
  );
};

export { Main };
