import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import './NewsSlider.scss';
import { Link } from 'react-router-dom';
import { NewsModel } from '../../shared/types/models/newsModel';
import { API } from '../../api/api';

interface Props {
  titleBlock?: string;
  modification?: string;
}

const articleOptionsSlider = {
  slidesToShow: 3,
  slidesToScroll: 3,
  infinite: true,
  dots: false,
  arrows: false,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        centerMode: true,
        centerPadding: '20px',
        slidesToShow: 1.05,
        slidesToScroll: 1,
      },
    },
  ],
};

const NewsSlider = ({ titleBlock, modification }: Props): React.ReactElement | null => {
  const [news, setNews] = useState<NewsModel[]>([]);

  const classModification = modification === '' ? '' : ` news-slider--${modification}`;

  useEffect(() => {
    API.getNewsListForSlider(3).then((r) => {
      if (r) {
        setNews(r);
      }
    });
  }, []);

  if (news.length === 0) {
    return null;
  }

  return (
    <div className={`news-slider${classModification}`}>
      <div className="container">
        <div className="news-slider__header">
          <div className="news-slider__title">{titleBlock}</div>
          <Link to="/news" className="news-slider__link">
            все новости
            <svg width="13" height="13" viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.25 10.5C12.6642 10.5 13 10.1642 13 9.74998L13 0.75C13 0.335787 12.6642 1.68587e-06 12.25 1.3487e-06L3.25 0C2.83579 0 2.5 0.335786 2.5 0.749999C2.5 1.16421 2.83579 1.5 3.25 1.5L10.4393 1.5L0.21967 11.7197C-0.0732233 12.0125 -0.0732234 12.4874 0.21967 12.7803C0.512563 13.0732 0.987437 13.0732 1.28033 12.7803L11.5 2.56066L11.5 9.74998C11.5 10.1642 11.8358 10.5 12.25 10.5Z"
              />
            </svg>
          </Link>
        </div>
        <Slider {...articleOptionsSlider}>
          {news &&
            news.map(({ id, previewImage, title, titleShort, date, url }) => {
              return (
                <div key={id} className="news-slider__item">
                  <div className="news-slider__img-wrapper">
                    {previewImage && (
                      <img src={previewImage.url} alt={title} className="news-slider__img" />
                    )}
                  </div>
                  <span className="news-slider__date">{date}</span>
                  <Link to={url} className="news-slider__name" title={title}>
                    {titleShort}
                  </Link>
                </div>
              );
            })}
        </Slider>
      </div>
    </div>
  );
};

NewsSlider.defaultProps = {
  titleBlock: 'Может быть интересно',
  modification: '',
};

export { NewsSlider };
