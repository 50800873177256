import React, { ReactElement } from 'react';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { Main } from '../components/main/Main';

const MainPage = (): ReactElement => {
  return (
    <DefaultLayout>
      <Main />
    </DefaultLayout>
  );
};

export { MainPage };
