import React, { ReactElement, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import './Navmenu.scss';
import { useEventListener } from '../../functions/useIsomorphicLayoutEffect/useEventListener';
import { NavMenuItem } from './NavMenuItem';
import { BurgerMenu } from './BurgerMenu';
import { getNavMenuData, DefaultItems } from '../../dataFiles/NavMenuData';
import { clickNavLink } from '../../functions/helpers/clickNavLink';

interface Props {
  footer: boolean;
}

const NavMenu = ({ footer }: Props): ReactElement => {
  const params = useParams();
  const navMenu = getNavMenuData(params);

  const [isMobile, setIsMobile] = useState(false);
  const resizeHandle = () => {
    const width = document.documentElement.clientWidth;
    if (width > 1100) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  useEventListener('resize', resizeHandle);
  const [isOpen, setIsOpen] = useState(false);
  const data = footer ? DefaultItems : navMenu;
  const navClass = footer
    ? 'nav-menu__wrapper'
    : `nav-menu__wrapper ${isOpen ? 'visible' : 'hidden'}`;

  function handleClickLink(evt: React.MouseEvent<HTMLElement, MouseEvent>) {
    clickNavLink(evt);
    setIsOpen(false);
  }

  return (
    <nav className="nav-menu">
      {footer || <BurgerMenu setIsOpen={setIsOpen} isOpen={isOpen} />}
      {!footer && isOpen && <img src="/img/logo-dark.svg" className="nav-menu__logo" alt="logo" />}
      <ul className={isMobile ? 'nav-menu__wrapper' : navClass}>
        {data.map((item) => {
          return (
            <NavMenuItem
              key={item.id}
              name={item.name}
              src={item.src}
              handleClick={handleClickLink}
            />
          );
        })}
      </ul>
    </nav>
  );
};

export { NavMenu };
