import React, { ReactElement } from 'react';
import { MainArticlePages } from '../components/main/MainArticlePages';
import { DefaultLayout } from '../layouts/DefaultLayout';

const ArticlePages = (): ReactElement => {
  return (
    <DefaultLayout logo="/img/logo-dark-header.svg">
      <MainArticlePages />
    </DefaultLayout>
  );
};

export { ArticlePages };
