import { ListResponse } from '../types/strapi/response';
import { DirectionsDetailModel } from '../types/models/directionsModel';
import { Directions } from '../types/strapi/directions';
import { getBlocks } from '../utils/getBlocks';
import { mapMentorsFromDTO } from './mapMentorsFromDTO';

export const mapDirectionFromDTO = (
  response: ListResponse<Directions>
): DirectionsDetailModel | undefined => {
  if (response.data.length > 0) {
    const firstElement = response.data[0];

    return {
      id: firstElement.id,
      slug: firstElement.attributes.Slug,
      title: firstElement.attributes.title,
      description: firstElement.attributes.description,
      blocks: firstElement.attributes.blocks && getBlocks(firstElement.attributes.blocks),
      mentors:
        firstElement.attributes.mentors && mapMentorsFromDTO(firstElement.attributes.mentors),
    };
  }
  return undefined;
};
