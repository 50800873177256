import React, { ReactElement } from 'react';
import { Logo } from '../logo/logo';
import { NavMenu } from '../navMenu/Navmenu';
import { Copyright } from './Copyright';
import './Footer.scss';

interface Props {
  modification?: string;
  logo?: string;
}

const Footer = ({ modification, logo = '/img/logo.svg' }: Props): ReactElement => {
  return (
    <footer className={`footer ${modification}`}>
      <div className="container footer__wrapper">
        <Logo src={logo} />
        <NavMenu footer />
        <Copyright />
      </div>
    </footer>
  );
};

Footer.defaultProps = {
  logo: '/img/logo.svg',
  modification: '',
};

export { Footer };
