import React, { ReactElement } from 'react';
import './Methods.scss';
import { MethodsItem } from './MethodsItem';
import { MethodsData } from '../../dataFiles/MethodsData';

const Methods = (): ReactElement => {
  return (
    <section className="methods" id="methods">
      <div className="container methods__wrapper">
        <div className="methods__descr">
          <h2 className="h2 methods__title">Детали обучения</h2>
          <div className="methods__subtitle">К каждому ученику индивидуальный подход</div>
          <a href="#directions" className="btn methods__btn">
            Выбрать направление
          </a>
        </div>
        <ul className="methods__items-wrapper">
          {MethodsData.map((item) => {
            return (
              <MethodsItem
                key={item.id}
                title={item.title}
                previewDescription={item.previewDescription}
                description={item.description}
                text={item.link.text}
                src={item.link.src}
              />
            );
          })}
        </ul>
      </div>
    </section>
  );
};

export { Methods };
