import React, { ReactElement } from 'react';
import { useCookies } from 'react-cookie';
import './Cookie.scss';

const Cookie = (): ReactElement => {
  const [cookie, setCookie] = useCookies(['cookie-policy']);

  function onChange() {
    setCookie('cookie-policy', 'true', { path: '/', maxAge: 31536000 });
  }

  return (
    <div className="cookie">
      <div className="container">
        <p className="cookie__text">
          Мы используем cookie-файлы для наилучшего представления нашего сайта.
          <br />
          Продолжая использовать этот сайт, вы соглашаетесь с использованием cookie-файлов.
        </p>
        <button className="cookie__btn animated-arrow" onClick={onChange} type="button">
          <span className="the-arrow -left">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M-4.94705e-07 6.75L9.1275 6.75L4.93875 10.9387L6 12L12 6L6 4.29138e-07L4.93875 1.06125L9.1275 5.25L-3.63571e-07 5.25L-4.94705e-07 6.75Z"
                fill="white"
              />
            </svg>
          </span>{' '}
          <span className="main">
            <span className="text">Понятно</span>{' '}
            <span className="the-arrow -right">
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M-4.94705e-07 6.75L9.1275 6.75L4.93875 10.9387L6 12L12 6L6 4.29138e-07L4.93875 1.06125L9.1275 5.25L-3.63571e-07 5.25L-4.94705e-07 6.75Z"
                  fill="white"
                />
              </svg>
            </span>
          </span>
        </button>
      </div>
    </div>
  );
};

export { Cookie };
