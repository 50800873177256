import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Wysiwyg } from '../wysiwyg/Wysiwyg';
import './Article.scss';
import { API } from '../../api/api';
import { NewsModel } from '../../shared/types/models/newsModel';
import { LinkTo } from '../linkTo/LinkTo';

const Article = (): ReactElement | null => {
  const params = useParams();
  const [news, setNews] = useState<NewsModel>();

  useEffect(() => {
    if (params.alias) {
      API.getNewsBySlug(params.alias).then((r) => {
        if (r) {
          setNews(r);
        } else {
          window.location.href = '/404';
        }
      });
    }
  }, [params]);

  if (!news) {
    return null;
  }

  const image = news.detailImage || news.previewImage;

  return (
    <article className="article">
      <div className="container">
        <div className="article__banner">
          {image && <img src={image.url} alt={news.title} className="article__img" />}
          <div className="article__banner-descr">
            <div className="article__date">{news.date}</div>
            <div className="article__name">{news.title}</div>
          </div>
        </div>

        <Wysiwyg className="article__text-wrapper">{news.detailText}</Wysiwyg>

        <div className="article__text-wrapper">
          {news.useful_links && news.useful_links.length > 0 && (
            <>
              <div className="article__h3">Полезные ссылки:</div>
              <ul className="article__healthy">
                {news.useful_links.map((item) => (
                  <li className="article__healthy-item" key={item.id}>
                    <LinkTo href={item.href} className="article__healthy-link">
                      {item.title}{' '}
                      <svg
                        width="13"
                        height="13"
                        viewBox="0 0 13 13"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12.25 10.5C12.6642 10.5 13 10.1642 13 9.74998L13 0.75C13 0.335787 12.6642 1.68587e-06 12.25 1.3487e-06L3.25 0C2.83579 0 2.5 0.335786 2.5 0.749999C2.5 1.16421 2.83579 1.5 3.25 1.5L10.4393 1.5L0.21967 11.7197C-0.0732233 12.0125 -0.0732234 12.4874 0.21967 12.7803C0.512563 13.0732 0.987437 13.0732 1.28033 12.7803L11.5 2.56066L11.5 9.74998C11.5 10.1642 11.8358 10.5 12.25 10.5Z"
                        />
                      </svg>
                    </LinkTo>
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      </div>
    </article>
  );
};

export { Article };
