import React, { ReactElement } from 'react';
import './Banner.scss';
import { clickNavLink } from '../../functions/helpers/clickNavLink';
import { Wysiwyg } from '../wysiwyg/Wysiwyg';
import { BannersModel } from '../../shared/types/models/bannersModel';

interface PageProps {
  data?: BannersModel[];
}

export const Banner = ({ data }: PageProps): ReactElement | null => {
  if (!data || data.length === 0) {
    return null;
  }

  const banner = data[0];

  return (
    <section className={`main-banner ${banner.cssMode || ''}`}>
      <div className="container">
        <h1 className="main-banner__title">
          <span className="main-banner__title-part1">{banner.title}</span>{' '}
          <span className="main-banner__title-part2">{banner.subTitle}</span>
        </h1>
        <div className="main-banner__descr">
          <span className="main-banner__shadow" />
          {banner.description && (
            <Wysiwyg className="main-banner__text">{banner.description}</Wysiwyg>
          )}
          {banner.buttonSrc && banner.buttonText && (
            <a
              href={banner.buttonSrc}
              className="btn main-banner__choise"
              onClick={(evt) => clickNavLink(evt)}
            >
              {banner.buttonText}
            </a>
          )}
        </div>
      </div>
    </section>
  );
};

Banner.defaultProps = {
  data: undefined,
};
