import React, { ReactElement } from 'react';
import { Article } from '../Article/Article';
import './Main.scss';

const MainArticlePages = (): ReactElement => {
  return (
    <main className="main main--no-banner">
      <Article />
    </main>
  );
};

export { MainArticlePages };
