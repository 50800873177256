import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Banner } from '../main-banner/Banner';
import { RunningTape } from '../running-tape/RunningTape';
import { Reviews } from '../reviews/Reviews';
import { Questions } from '../questions/Questions';
import { Form } from '../form/Form';
import { Programs } from '../program/Programs';
import { Mentors } from '../mentors/Mentors';
import { API } from '../../api/api';
import { DirectionsDetailModel } from '../../shared/types/models/directionsModel';

const MainDirectionsPages = (): ReactElement | null => {
  const params = useParams();
  const [direction, setDirection] = useState<DirectionsDetailModel>();

  useEffect(() => {
    if (params.direction) {
      API.getDirectionBySlug(params.direction).then((r) => {
        if (r) {
          setDirection(r);
        } else {
          window.location.href = '/404';
        }
      });
    }
  }, [params]);

  if (!direction) {
    return null;
  }

  return (
    <main className="main">
      <Banner
        data={
          direction?.blocks?.banner || [
            { id: direction.id, title: direction.title, description: direction.description },
          ]
        }
      />
      <RunningTape />
      <Programs data={direction?.blocks?.program} />
      <Mentors data={direction.mentors} />
      <Reviews />
      <Questions />
      <Form />
    </main>
  );
};

export { MainDirectionsPages };
