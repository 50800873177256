import React, { ReactElement } from 'react';
import './Mentor.scss';
import { Image } from '../../shared/types/strapi/image';

interface Props {
  name: string;
  position: string;
  image?: Image;
}

const Mentor = ({ name, position, image }: Props): ReactElement => {
  return (
    <figure className="mentor">
      <div className="mentor__img-block">
        {image && <img src={image.url} alt={name} className="mentor__img" />}
      </div>
      <figcaption>
        <div className="mentor__name">{name}</div>
        <div className="mentor__position">{position}</div>
      </figcaption>
    </figure>
  );
};

Mentor.defaultProps = {
  image: undefined,
};

export { Mentor };
