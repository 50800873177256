import { ListResponse } from '../types/strapi/response';
import { DirectionsModel } from '../types/models/directionsModel';
import { Directions } from '../types/strapi/directions';

export const mapDirectionsFromDTO = (
  response: ListResponse<Directions>
): DirectionsModel[] | undefined => {
  if (response.data.length > 0) {
    return response.data.map((d) => {
      return {
        id: d.id,
        url: `/directions/${d.attributes.Slug}`,
        slug: d.attributes.Slug,
        title: d.attributes.title,
        description: d.attributes.description,
      };
    });
  }
  return undefined;
};
