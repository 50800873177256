import { ListResponse } from '../types/strapi/response';
import { Questions } from '../types/strapi/questions';
import { QuestionsModel } from '../types/models/questionsModel';
import { sortArray } from '../utils/sortArray';

export const mapQuestionsFromDTO = (
  response: ListResponse<Questions>
): QuestionsModel[] | undefined => {
  if (response.data.length > 0) {
    const data = response.data.map((d) => {
      return {
        id: d.id,
        answer: d.attributes.answer,
        question: d.attributes.question,
        sort: d.attributes.sort,
      };
    });

    return sortArray<QuestionsModel>(data, 'sort');
  }
  return undefined;
};
