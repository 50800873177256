import React, { ReactElement, useState } from 'react';
import Slider from 'react-slick';

import './About.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { AboutData } from '../../dataFiles/AboutData';
import { LinkWithArrow } from '../linkWithArrow/LinkWithArrow';
import { Achievements } from './Achievements';
import { Projects } from './Projects';
import { AboutSliderBanner } from './AboutSliderBanner';

const About = (): ReactElement => {
  const OptionsProjectsSlider = {
    slidesToShow: 2,
    slidesToScroll: 2,
    infinite: false,
    dots: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1281,
        settings: {
          variableWidth: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          adaptiveHeight: true,
        },
      },
    ],
  };

  const [isOpen, setIsOpen] = useState(false);

  return (
    <section className="about" id="about">
      <div className="container">
        <h2 className="h2 about__title">О нас</h2>
        <AboutSliderBanner />
        <div className="about__descr">
          <div className="about__text">
            Решаем задачи стартапов и&#160;крупного бизнеса. Делаем веб&#8209;сервисы, от&#160;идеи
            до&#160;запуска.
          </div>
          <LinkWithArrow
            text="Подробнее о kokoc.tech"
            src="https://kokoc.tech/"
            ClassName="about__more-link"
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        </div>
        <Achievements AddClass="about__wrapper" />
        <article className="about__projects about__wrapper">
          <div className="about__title-wrapper">
            <h3 className="h3 about__projects-h3">Наши проекты</h3>
          </div>
          <div className="about__projects-slider">
            <Slider {...OptionsProjectsSlider}>
              {AboutData.map((item, index) => {
                return (
                  <Projects
                    key={item.id}
                    title={item.title}
                    description={item.description}
                    src={item.src}
                    url={item.url}
                    addClassName={index % 2 > 0 ? 'odd' : 'even'}
                  />
                );
              })}
              {AboutData.length % 2 > 0 && (
                <div className="slick-slider about__projects-slider-plug" />
              )}
            </Slider>
          </div>
        </article>
      </div>
    </section>
  );
};

export { About };
