import React, { ReactChildren, ReactChild, ReactElement, useEffect } from 'react';
import './ModalWindow.scss';

interface Props {
  children: ReactChild | ReactChildren;
  setIsOpen: (value: boolean) => void;
  isOpen: boolean;
}

const ModalWindow = ({ children, setIsOpen, isOpen }: Props): ReactElement => {
  useEffect((): void => {
    document.documentElement.classList.add('no-scroll');
  }, []);

  const onClickHandler = (
    evt:
      | React.MouseEvent<HTMLDivElement, MouseEvent>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    const element = evt.target;

    if (
      element instanceof HTMLElement &&
      (element.classList.contains('modal') || element.classList.contains('modal__close-btn'))
    ) {
      document.documentElement.classList.remove('no-scroll');
      setIsOpen(!isOpen);
    }
  };

  return (
    <div
      className="modal"
      role="button"
      tabIndex={0}
      onClick={onClickHandler}
      onKeyPress={() => {}}
    >
      <div className="modal__body">
        <div className="modal__content">{children}</div>
        <button type="button" className="modal__close-btn" onClick={(evt) => onClickHandler(evt)}>
          {' '}
        </button>
      </div>
    </div>
  );
};

export { ModalWindow };
