import React, { ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useEventListener } from '../../functions/useIsomorphicLayoutEffect/useEventListener';
import { NavMenu } from '../navMenu/Navmenu';
import { Logo } from '../logo/logo';
import './Header.scss';

interface HeaderProps {
  logo: string;
}

const Header = ({ logo }: HeaderProps): ReactElement => {
  const location = useLocation();
  const [scroll, setScrolling] = useState(false);

  const scrollHandle = () => {
    const scrollTop = window.pageYOffset;
    if (scrollTop > 50) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    scrollHandle();
  }, [location.pathname]);

  useEventListener('scroll', scrollHandle);
  const srcLogo = scroll ? '/img/logo.svg' : logo;
  return (
    <header className={!scroll ? 'header' : 'header scrolling'}>
      <div className="container header__wrapper">
        <Logo src={srcLogo} />
        <NavMenu footer={false} />
      </div>
    </header>
  );
};

export { Header };
