import * as axios from 'axios';

export const instanceApi = axios.default.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`,
  },
});
