import React, { ReactElement } from 'react';
import Slider from 'react-slick';

import './AboutSliderBanner.scss';

import { AboutSlider } from '../../dataFiles/AboutBannerSliderData';

const AboutSliderBanner = (): ReactElement => {
  const OptionsBannerSlider = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    dots: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className="about-slider">
      <Slider {...OptionsBannerSlider}>
        {AboutSlider.map((item, index) => {
          return (
            <div key={item.src} className="about__slide">
              <img
                src={item.src}
                alt={`Слайдер "О нас" слайд № ${index + 1}`}
                className="about__slider-img"
              />
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export { AboutSliderBanner };
