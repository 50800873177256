import React, { ReactElement, useEffect, useState } from 'react';
import './News.scss';
import { Link } from 'react-router-dom';
import { CircleSwitch } from '../circleSwitch/CircleSwitch';

import { API } from '../../api/api';
import { NewsModel } from '../../shared/types/models/newsModel';
import { Pagination } from '../../shared/types/strapi/response';

const News = (): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const [news, setNews] = useState<NewsModel[]>([]);
  const pageSize = 10;
  const [pagination, setPagination] = useState<Pagination>();
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    API.getNewsList(pageSize).then((r) => {
      if (r.data) {
        setNews(r.data);
      }
      setPagination(r.pagination);
    });
  }, []);

  const loadMore = () => {
    if (!isOpen) {
      const pageNewNumber = pageNumber + 1;
      setPageNumber(pageNewNumber);

      API.getNewsList(pageSize, pageNewNumber).then((r) => {
        if (r.data) {
          setNews([...news, ...r.data]);
        }
      });

      if (pagination && pageNewNumber === pagination.pageCount) {
        setIsOpen(true);
      }
    }
  };

  return (
    <section id="news" className={`news ${isOpen ? 'open' : ''}`}>
      <div className="container">
        <ul className="news__list">
          {news.map(({ id, date, previewImage, title, url }) => {
            return (
              <li className="news__item" key={id}>
                <div className="news__img-wrapper">
                  {previewImage && <img src={previewImage.url} alt={title} className="news__img" />}
                </div>
                <div className="news__content">
                  <div className="news__date">{date}</div>
                  <Link to={url} className="news__name" title={title}>
                    {title}
                  </Link>
                </div>
              </li>
            );
          })}
        </ul>

        {pagination && pageNumber < pagination.pageCount && (
          <CircleSwitch
            isOpen={isOpen}
            text={isOpen ? '' : 'Больше новостей'}
            setIsOpen={loadMore}
          />
        )}
      </div>
    </section>
  );
};

export { News };
