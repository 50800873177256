import React, { ReactElement } from 'react';
import { AchievementsItems } from './AchievementsItems';
import { AchievementsData } from '../../dataFiles/AchievementsData';
import './Achievements.scss';

interface Props {
  AddClass: string;
}

const Achievements = ({ AddClass }: Props): ReactElement => {
  const WrapperClassName = `achievements ${AddClass}`;
  return (
    <article className={WrapperClassName}>
      <div className="achievements__descr">
        <h3 className="h3 achievements__title">Наши достижения</h3>
        <div className="achievements__text">
          Каждый год мы доказываем свой профессионализм. По&#160;данным Рейтинга Рунета:
        </div>
      </div>
      <ul className="achievements__items-wrapper">
        {AchievementsData.map((item) => {
          return (
            <li className="achievements__item" key={item.id}>
              <AchievementsItems
                key={item.id}
                title={item.title}
                total={item.total}
                active={item.active}
              />
            </li>
          );
        })}
      </ul>
    </article>
  );
};

export { Achievements };
