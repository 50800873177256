import React, { ReactElement } from 'react';
import './BurgerMenu.scss';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const BurgerMenu = ({ isOpen, setIsOpen }: Props): ReactElement => {
  const ClassName = `burger-menu ${isOpen ? 'active' : ''}`;

  function toggleBurger() {
    setIsOpen(!isOpen);
  }

  return (
    <button className={ClassName} type="button" onClick={toggleBurger}>
      {' '}
    </button>
  );
};

export { BurgerMenu };
