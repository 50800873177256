import React, { ReactElement } from 'react';
import './Projects.scss';

interface Props {
  title: string;
  description: string;
  src: string;
  url: string;
  addClassName: string;
}

const Projects = ({ title, description, src, url, addClassName }: Props): ReactElement => {
  return (
    <figure className={`projects ${addClassName}`}>
      <a className="projects__link" href={url} target="_blank" rel="noopener noreferrer">
        <img src={src} alt={title} className="projects__img" />
        <figcaption className="projects__descr">
          <span className="projects__title">{title}</span>
          <div className="projects__text">{description}</div>
          <i className="btn btn--open projects__btn" />
        </figcaption>
      </a>
    </figure>
  );
};

export { Projects };
