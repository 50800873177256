import React, { ReactElement } from 'react';
import './LinkWithArrow.scss';

interface Props {
  text: string;
  src: string;
  ClassName: string;
  setIsOpen: (value: boolean) => void;
  isOpen?: boolean;
}

const LinkWithArrow = ({ text, src, ClassName, setIsOpen, isOpen }: Props): ReactElement => {
  const newClassName = `link-with-arrow ${ClassName}`;
  const handler = (e: React.SyntheticEvent): void => {
    if (src === '') {
      e.preventDefault();
    }
    setIsOpen(!isOpen);
  };
  return (
    <a
      href={src}
      target="_blank"
      rel="noreferrer"
      className={newClassName}
      onClick={(e) => handler(e)}
    >
      {text}
      <svg
        className="link-with-arrow__arrow"
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
        stroke="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.25 10.5C12.6642 10.5 13 10.1642 13 9.74998L13 0.75C13 0.335787 12.6642 1.68587e-06 12.25 1.3487e-06L3.25 0C2.83579 0 2.5 0.335786 2.5 0.749999C2.5 1.16421 2.83579 1.5 3.25 1.5L10.4393 1.5L0.21967 11.7197C-0.0732233 12.0125 -0.0732234 12.4874 0.21967 12.7803C0.512563 13.0732 0.987437 13.0732 1.28033 12.7803L11.5 2.56066L11.5 9.74998C11.5 10.1642 11.8358 10.5 12.25 10.5Z"
          fill="#F26050"
        />
      </svg>
    </a>
  );
};

LinkWithArrow.defaultProps = {
  isOpen: false,
};

export { LinkWithArrow };
