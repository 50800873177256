import React, { ReactElement, useEffect, useState } from 'react';
import './Reviews.scss';
import { Review } from './Review';
import { CircleSwitch } from '../circleSwitch/CircleSwitch';
import { ReviewsModel } from '../../shared/types/models/reviewsModel';
import { API } from '../../api/api';
import { useGlobalAppContext } from '../../shared/contexts/AppContext';

export const Reviews = (): ReactElement | null => {
  const { reviews } = useGlobalAppContext();
  const [closed, SetClosed] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  if (reviews.length === 0) {
    return null;
  }

  return (
    <section id="reviews" className={`reviews ${isOpen ? 'active' : ''}`}>
      <div className="container">
        <h2>Отзывы учеников</h2>
        <div className="reviews__wrapper">
          {reviews.map((item) => {
            return <Review key={item.id} {...item} />;
          })}
        </div>
        {/* <div */}
        {/*  role="button" */}
        {/*  className="reviews__more-button" */}
        {/*  onClick={() => SetClosed(!closed)} */}
        {/*  onKeyDown={() => SetClosed(!closed)} */}
        {/*  tabIndex={0} */}
        {/* > */}
        {/*  <CircleSwitch */}
        {/*    isOpen={isOpen} */}
        {/*    text={closed ? 'Больше отзывов' : ''} */}
        {/*    setIsOpen={setIsOpen} */}
        {/*  /> */}
        {/* </div> */}
      </div>
    </section>
  );
};
