import React, { ReactElement, useEffect, useState } from 'react';
import './Directions.scss';
import { DirectionsItems } from './DirectionsItems';
import { useGlobalAppContext } from '../../shared/contexts/AppContext';

const Directions = (): ReactElement => {
  const { recruitmentText, directions } = useGlobalAppContext();

  return (
    <section className="directions" id="directions">
      <div className="container">
        <div className="directions__title-wrapper">
          <h2 className="h2 directions__title">Направления</h2>
          <div className="directions__subtitle">{recruitmentText.directionDesc}</div>
        </div>
        <ul className="directions__wrapper">
          {directions &&
            directions.map((item) => {
              return <DirectionsItems {...item} key={item.id} />;
            })}
        </ul>
      </div>
    </section>
  );
};

Directions.defaultProps = {};
export { Directions };
